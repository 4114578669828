import { runInInjectionContext, inject, DestroyRef, isSignal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { assertInjector } from 'ngxtension/assert-injector';
import { Subject, retry, isObservable, of } from 'rxjs';

/**
 * This code is a copied `ComponentStore.effect()` method from NgRx and edited to:
 * 1) be a standalone function;
 * 2) use `takeUntilDestroyed()` with an injected `DestroyRef`;
 * 3) resubscribe on errors.
 *
 * Credits: NgRx Team
 * https://ngrx.io/
 * Source: https://github.com/ngrx/platform/blob/main/modules/component-store/src/component-store.ts#L382
 * Docs:
 * https://ngrx.io/guide/component-store/effect#effect-method
 */
function createEffect(generator, options) {
  const injector = assertInjector(createEffect, options?.injector);
  return runInInjectionContext(injector, () => {
    const destroyRef = inject(DestroyRef);
    const origin$ = new Subject();
    const retryOnError = !!(options?.retryOnError ?? true);
    const retryConfig = typeof options?.retryOnError === 'object' && options?.retryOnError ? options?.retryOnError : {};
    if (retryOnError) {
      generator(origin$).pipe(retry(retryConfig), takeUntilDestroyed(destroyRef)).subscribe();
    } else {
      generator(origin$).pipe(takeUntilDestroyed(destroyRef)).subscribe();
    }
    return observableOrValue => {
      const observable$ = isObservable(observableOrValue) ? observableOrValue : isSignal(observableOrValue) ? toObservable(observableOrValue, {
        injector
      }) : of(observableOrValue);
      if (retryOnError) {
        return observable$.pipe(retry(retryConfig), takeUntilDestroyed(destroyRef)).subscribe(value => {
          origin$.next(value);
        });
      } else {
        return observable$.pipe(takeUntilDestroyed(destroyRef)).subscribe(value => {
          origin$.next(value);
        });
      }
    };
  });
}

/**
 * Generated bundle index. Do not edit.
 */

export { createEffect };
